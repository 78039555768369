<template>
	<div class="delivery-wr">
		<div class="delivery-sorry">
			<transition name="popup-fade">
				<div
					class="delivery-sorry-state"
					v-if="!showSubscribe"
				>
					<div class="delivery-sorry__title">К сожалению, мы пока не можем привезти еду по указанному адресу
					</div>
					<div class="delivery-sorry__bottom">
						<button
							class="button delivery-sorry__btn"
							@click="showSubscribeForm()"
						>Сообщить, когда сможем?
						</button>
						<button
							class="button button_red delivery-sorry__btn"
							@click="changeAddress()"
						>Изменить адрес доставки
						</button>
					</div>
				</div>
				<div
					class="delivery-inform-state"
					v-if="showSubscribe"
				>
					<Subscribe @closePopup="$emit('closePopup')"/>
				</div>
			</transition>
		</div>
	</div>
</template>
<script>
import Subscribe from '@/components/form/Subscribe.vue';

export default {
	components: {Subscribe},
	data()
	{
		return {
			showSubscribe: false
		}
	},
	methods:
		{
			/**
			 * Показывает форму подписки
			 */
			showSubscribeForm()
			{
				this.showSubscribe = true;
			},

			/**
			 * Пользователь хочет изменить адрес доставки
			 */
			changeAddress()
			{
				this.$emit('changeAddress');
			}
		}
}
</script>
<style lang="scss">
.delivery-sorry-wr
{
	width: 1200px;
}

.delivery-sorry
{
	border-radius: 10px;
	padding: 53px 39px 39px 36px;
	box-sizing: border-box;
	letter-spacing: .1px;

	.search__btn
	{
		height: auto;
	}
}

.delivery-sorry__btn
{
	cursor: pointer;
	font-family: $mainFontMedium;
	height: 50px;
	font-size: 16px;
	border-radius: 8px;
}

.delivery-sorry__title
{
	font-family: $mainFontBold;
	font-size: 24px;
	line-height: 120%;
	color: $textColor;
	margin-bottom: 25px;
}

.delivery-sorry__title
{
	max-width: 700px;
	width: 100%;
	text-align: center;
	margin: 0 auto 40px;
}

.delivery-sorry__bottom
{
	max-width: 587px;
	width: 100%;
	margin: 0 auto 33px;
	display: flex;
}

.delivery-sorry__btn
{
	width: 280px;

	&:not(:last-child)
	{
		margin-right: 25px;
	}
}

@media (max-width: 1200px)
{
	.delivery-sorry-wr
	{
		max-width: 100vw;
	}
	.delivery-sorry
	{
		padding: 25px 0 0;
	}
	.delivery-sorry__title
	{
		font-size: 22px;
		width: 70%;
		margin-bottom: 18px;
	}
}

@media (max-width: 990px)
{
	.delivery-sorry
	{
		&__btn
		{
			width: 100%;

			&:not(:last-child)
			{
				margin-bottom: 25px;
			}
		}

		&__title
		{
			width: 100%;
			font-size: 20px;
		}
	}
}

@media (max-width: 767px)
{
	.delivery-sorry
	{
		height: 100vh;
		padding: 25px 15px;

	}
	.delivery-sorry__title
	{
		margin-bottom: 25px;
		font-size: 22px;
	}
	.delivery-sorry__bottom
	{
		flex-direction: column;
	}
}
</style>
