<template>
	<div
		class="subscribe-wr"
		:class="{'loading': loading}"
	>
		<div class="subscribe__header">
			<div class="subscribe__title">Сообщить, когда сможем</div>
		</div>
		<form class="subscribe">
			<div class="subscribe__top">
				<label class="input-wrapper subscribe__top-input-wrapper">
					<label class="input-wrapper">
						<div class="send-sms-to-phone__input-wrapper no-its-not">
							<input
								class="send-sms-to-phone__phone-input"
								type="text"
								v-model="formFields.name.value"
								id="subscribe-name"
							/>
							<label
								class="send-sms-to-phone__phone-label"
								:class="{'send-sms-to-phone__phone-label_active': formFields.name.value !== ''}"
								for="subscribe-name"
							>Ваше имя
							</label>
							<div
								class="send-sms-to-phone__clear"
								:class="{'send-sms-to-phone__clear_hidden': formFields.name.value === ''}"
								@click="clearInput('name')"
							></div>
						</div>
					</label>
					<span
						class="input-wrapper__label-error"
						:style="{'display': formFields.name.error ? 'block' : 'none'}"
						v-html="formFields.name.error"
					/>
				</label>
				<label class="input-wrapper subscribe__top-input-wrapper">
					<label class="input-wrapper">
						<div class="send-sms-to-phone__input-wrapper no-its-not">
							<input
								class="send-sms-to-phone__phone-input"
								type="text"
								v-model="formFields.contact.value"
								id="subscribe-mail"
							/>
							<label
								class="send-sms-to-phone__phone-label"
								for="subscribe-mail"
								:class="{'send-sms-to-phone__phone-label_active': formFields.contact.value !== ''}"
							>Телефон или e-mail
							</label>
							<div
								class="send-sms-to-phone__clear"
								:class="{'send-sms-to-phone__clear_hidden': formFields.contact.value === ''}"
								@click="clearInput('contact')"
							></div>
						</div>
					</label>
					<span
						class="input-wrapper__label-error"
						:style="{'display': formFields.contact.error ? 'block' : 'none'}"
						v-html="formFields.contact.error"
					/>
				</label>
			</div>
			<div class="subscribe__bottom">
				<p class="subscribe__info">Заполняя данную форму и нажимая кнопку «Отправить» я даю свое согласие на
					обработку моих персональных данных,
					в соответствии с Федеральным законом от 27.07.2006 года №152-Ф3 «О персональных данных», на условиях
					и для
					целей, определенных в Политике конфиденциальности</p>
				<button
					class="button button_red subscribe__btn"
					@click.prevent="sendForm"
				>Отправить
				</button>
			</div>
		</form>
		<Popup
			v-if="isMobile"
			:visible.sync="isTermsModalVisible"
		>
			<Terms
				:terms-text-mode="dataTermsTextMode"
				@close="isTermsModalVisible = false"
			/>
		</Popup>
	</div>
</template>

<script>
import qs from 'qs';
import Validation from '@/mixins/validator.js';
import Terms from '@/components/popup/Terms.vue';
import {mapGetters} from "vuex";

export default {
	components: {Terms},
	mixins: [Validation],
	/**
	 * Глобальные переменные страницы
	 */
	data()
	{
		return {
			formFields:
				{
					name: {value: '', type: 'text', error: '', required: true},
					contact: {value: '', type: 'text', error: '', required: true},
				},
			loading: false,
			isTermsModalVisible: false,
			dataTermsTextMode: '',
			isMobile: process.env.VUE_APP_MOBILE
		}
	},
	computed: {
		...mapGetters({
			invalidAddress: 'getInvalidAddress',
		}),
	},
	methods:
		{
			/**
			 * Отправить заявку
			 */
			async sendForm()
			{
				if (this.loading)
					return false;

				this.loading = true;

				if (this.isNotValid(this.formFields))
				{
					this.loading = false;
					return false;
				}

				let phoneError = this.validatePhone(this.formFields.contact.value);
				let emailError = this.validateEmail(this.formFields.contact.value);
				if (phoneError && emailError)
				{
					this.formFields.contact.error = 'Некорректные контактные данные';
					this.loading = false;
					return false;
				}

				let data = qs.stringify({
					name: this.formFields.name.value,
					contact: this.formFields.contact.value,
					deliveryAddress: this.invalidAddress
				});

				let result = await this.$axios({
					data: data,
					method: 'post',
					url: '/siteorders/create/',
				});

				if (result.data.success)
				{
					this.loading = false;
					this.$emit('closePopup');
					return false;
				}
				for (var fieldError in result.data.errors)
				{
					if (typeof this.formFields[fieldError] != 'undefined')
						this.formFields[fieldError].error = result.data.errors[fieldError];
				}
				this.loading = false;
			},

			clearInput(inputName)
			{
				if (!inputName)
					return;

				this.formFields[inputName].value = "";
			},
			close()
			{

				if (this.$route.name == 'MapPage')
				{
					this.$store.dispatch('openPopup', null);
					return;
				}

				this.$emit('closePopup', true);
			},
			showTerms(term)
			{
				this.dataTermsTextMode = term;
				this.isTermsModalVisible = true;
			}
		}
}
</script>

<style lang="scss">
.subscribe__error-label
{
	color: red;
}

.subscribe__info
{
	font-family: $mainFontNew;
	font-size: 12px;
	text-align: left;
	color: $greyLightText;
	line-height: 14px;
	max-width: 398px;
}

.subscribe-wr
{
	margin: 0px auto;
	width: 100%;

	.send-sms-to-phone__input-wrapper
	{
		//margin-bottom: 0;
	}

	&.loading
	{
		opacity: 0.5;
	}
}

.subscribe__header
{
	display: flex;
	justify-content: space-between;
	//cursor: pointer;
}

.subscribe__close
{
	position: absolute;
	cursor: pointer;
	right: 22px;
	top: 22px;
}

.subscribe__title
{
	text-align: start;
	font-family: $mainFontBoldNew;
	font-size: 24px;
	line-height: 120%;
	color: $textColor;
	margin-bottom: 25px;
}

.subscribe__top
{
	margin-bottom: 24px;
	display: flex;

}

.subscribe__top-input-wrapper
{
	max-width: 358px;
	width: 100%;

	&:first-child
	{
		margin-right: 25px;
	}

	.input-wrapper__label
	{
		font-size: 12px;
	}
}

.subscribe-checkbox
{
	max-width: 470px;
	width: 100%;
}

.subscribe-checkbox__icon
{
	min-width: 25px;
	margin-right: 19px;
}

.subscribe-checkbox__text
{
	font-family: $mainFontNew;
	font-size: 12px;
	line-height: 110%;
	display: inline;
	color: $greyText;

	.subscribe__link
	{
		font-size: 12px;
	}
}

.subscribe__bottom
{
	display: flex;
	justify-content: space-between;
	margin-left: 3px;
}

.subscribe__btn
{
	font-family: $mainFontBoldNew;
	font-weight: bold;
	width: 204px;
	border-radius: 8px;
	padding: 19px 0;
	height: fit-content;
}

@media (min-width: 576px)
{
	.no-its-not
	{
		margin-bottom: 0;
	}
}

@media (max-width: 1200px)
{
	.subscribe-wr
	{
		padding-bottom: 20px;
	}
}

@media (max-width: 990px)
{
	.subscribe
	{
		&-wr
		{
			width: 100%;
		}

		&__info
		{
			margin-right: 24px;
			max-width: 385px;
			font-size: 12px;
		}

		&__top
		{
			&-input
			{
				&-wrapper
				{
					max-width: 100%;
					margin-right: 0;
					margin-bottom: 0 !important;

					&:first-child
					{
						margin-right: 32px;
					}

					& .input-wrapper
					{
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

@media (max-width: 767px)
{
	.subscribe-wr
	{
		width: 100%;
	}
}

@media (max-width: 576px)
{
	.subscribe
	{
		&__btn
		{
			order: 1;
			width: 100%;
			margin-bottom: 16px;
		}

		&__info
		{
			order: 2;
			margin-right: 0;
			max-width: 100%;
		}

		&__top
		{
			margin-bottom: 0;
		}
	}
	.subscribe__top
	{
		flex-direction: column;
	}
	.subscribe__bottom
	{
		flex-direction: column;
	}
	.subscribe__top-input-wrapper
	{
		margin-bottom: 20px;
	}

	.no-its-not
	{
		margin-bottom: 18px;
	}
}

@media (max-width: 374px)
{
	.subscribe
	{
		.send-sms-to-phone__input-wrapper
		{
			height: 48px;
		}

		&__btn
		{
			height: 48px;
			padding: 0;
		}

		.no-its-not
		{
			margin-bottom: 16px;
		}
	}
}


</style>
