// todo: избавиться от этого модуля
export default {
	methods:
		{
			/**
			 * Валидация полей
			 */
			isNotValid(fields)
			{
				if (typeof fields == 'undefined')
					return true;

				var error = false;
				for (var field in fields)
				{
					field = fields[field];
					if (field.required !== true) continue;

					if (field.value == '')
						field.error = error = true;

					var funcName = 'validate' + field.type.substr(0, 1).toUpperCase() + field.type.substr(1);
					if (typeof this[funcName] !== "function")
						throw new Error('field type is not define');

					field.error = this[funcName](field.value);
					if (field.error.length !== undefined)
						error = true;
				}

				if (error == true)
					return error;
				if (typeof (fields.repassword) == 'undefined')
					return error;

				if (fields.repassword.value != fields.password.value)
				{
					fields.password.error = 'Пароли не совпадают';
					fields.repassword.error = 'Пароли не совпадают';
					error = true;
					return error;
				} else
				{
					fields.password.error = '';
					fields.repassword.error = '';
				}

				return error;
			},
			/**
			 * Валидация поля пароль
			 */
			validatePassword(password)
			{
				if (password.length < 8)
					return 'Пароль должен быть не менее 8 символов';

				return false;
			},
			/**
			 * Валидация поля телефон
			 */
			validatePhone(phone)
			{
				if (typeof phone == 'undefined' || phone == '')
					return 'Обязательное поле';

				var mask = /[^\d]+/g;
				var maskWithoutEight = /7?([1-7,9])\d{9}/g;

				phone = phone.replace(mask, '');

				if ((phone.length >= 11 && phone.length < 12)
					&& maskWithoutEight.test(phone))
					return false;

				return 'Телефон введен некорректно';
			},
			/**
			 * Валидация поля Email
			 */
			validateEmail(email)
			{
				if (typeof email == 'undefined' || email == '')
					return 'Обязательное поле';

				var mask = /^\w.+@\w+\.\w{2,10}$/gi;

				if (mask.test(email))
					return false;

				return 'Email введен некорректно';
			},
			/**
			 * Валидация на пустоту полей
			 */
			validateBool(field)
			{
				if (field == false)
					return 'Обязательное поле';

				return false;
			},
			/**
			 * Валидация поля с текстом
			 */
			validateText(text)
			{
				if (!text)
					return 'Обязательное поле';

				if (text.length < 2)
					return 'Поле должно быть не менее 2 символов';

				return false;
			},
			/**
			 * Валидация файла
			 */
			validateFile(file)
			{
				if (typeof file == 'undefined')
					return 'Пустое поле';

				if (file.length < 0)
					return 'Пустое поле';

				return false;
			}
		}
}