<template>
	<form
		@submit.prevent.stop=""
		class="search-wr"
		:class="{'basket-type': basketType}"
		v-click-outside="closeSuggestItemsPopup"
	>
		<label
			for=""
			class="search-label delivery-search"
			:class="{'search-label_focused' : focused, 'have-error': buildingRequired}"
			style="margin-bottom: 18px"
		>
			<div class="search-icon">
				<svg
					width="18"
					height="23"
					viewBox="0 0 18 23"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M9.00019 0C7.22081 0.00093786 5.48163 0.52919 4.00231 1.51803C2.523 2.50687 1.36991 3.91194 0.688716 5.55577C0.00751764 7.19959 -0.171239 9.00842 0.175026 10.7538C0.52129 12.4991 1.37704 14.1027 2.63419 15.362C4.26419 16.992 8.10019 19.35 8.32719 21.827C8.36119 22.197 8.63019 22.5 9.00019 22.5C9.37019 22.5 9.64019 22.197 9.67319 21.827C9.90019 19.35 13.7332 16.996 15.3622 15.367C16.6208 14.1082 17.478 12.5046 17.8254 10.7587C18.1727 9.01289 17.9947 7.20326 17.3138 5.55857C16.6329 3.91388 15.4797 2.50799 13.9999 1.5186C12.5201 0.529208 10.7803 0.000741466 9.00019 0ZM9.00019 12.079C8.59585 12.079 8.19547 11.9994 7.82191 11.8446C7.44834 11.6899 7.10892 11.4631 6.82301 11.1772C6.5371 10.8913 6.3103 10.5518 6.15556 10.1783C6.00083 9.80472 5.92119 9.40434 5.92119 9C5.92119 8.59566 6.00083 8.19528 6.15556 7.82172C6.3103 7.44816 6.5371 7.10873 6.82301 6.82282C7.10892 6.53691 7.44834 6.31011 7.82191 6.15538C8.19547 6.00064 8.59585 5.921 9.00019 5.921C9.81679 5.921 10.5999 6.24539 11.1774 6.82282C11.7548 7.40024 12.0792 8.1834 12.0792 9C12.0792 9.8166 11.7548 10.5998 11.1774 11.1772C10.5999 11.7546 9.81679 12.079 9.00019 12.079Z"
						fill="#FD470E"
					></path>
				</svg>
			</div>
			<span
				class="search-main-popup_error"
				v-if="buildingRequired"
			>{{ buildingRequiredText }}</span>
			<input
				type="text"
				ref="searchAddressInput"
				id="suggestPopup"
				name="search-main-popup"
				class="search input search__map_address-input"
				:class="{error: hasInputError}"
				autocomplete="off"
				:value="deliveryAddress"
				enterkeyhint="search"
				@input="changeAddress($event)"
				@focus="placeholderHideOnFocusSearchInput(); showAddressPopup();"
				@blur="placeholderShowOnBlurSearchInput()"
			>

			<label
				for="suggestPopup"
				class="search__map_address-label"
				:class="{'search__map_address-label_active': deliveryAddress !== ''}"
			>{{ focused || deliveryAddress !== '' ? 'Ваш адрес' : 'Куда доставить?' }}</label>

			<ul
				class="search-main-popup__suggestions"
				v-show="returnCutSuggest.length && !isMobile"
			>
				<li
					v-for="suggestItem in returnCutSuggest"
					class="search-main-popup__suggestion"
					:class="{'search-main-popup__suggestion_active': suggestItem.active}"
					@click="checkSelectedSuggestion(suggestItem, onlyClosePopup)"
				>
					{{ suggestItem.value }}
				</li>
			</ul>
		</label>
		<button
			class="search__btn search__btn_no-shadow"
			:class="{['loading']: loading, ['basket-type']: basketType}"
			:disabled="this.$store.state.map.disableSelectButton || isAvailable === 'no-rests'"
			@click="selectMapAddress()"
			v-show="!suggestActive"
		>
			<button-preloader v-if="selectInProgress"/>
			<span v-else>Везите сюда</span>
		</button>
		<AddressPopup
			v-if="addressPopupShown"
			@closePopup="closeAddressPopup"
			:onlyClosePopup="onlyClosePopup"
		/>
	</form>
</template>
<script>
import search from '@/mixins/search.js';
import AddressPopup from "@/components/popup/AddressPopup";
import {mapGetters} from "vuex";
import ButtonPreloader from '../ui/ButtonPreloader.vue';

export default {
	components: {
		AddressPopup,
		ButtonPreloader
	},
	mixins: [search],
	props: ["mapAddress", "loading", "isAvailable", "basketType", "onlyClosePopup"],
	/**
	 *
	 */
	data()
	{
		return {
			addPopupVisible: false,
			selectInProgress: false,
			suggestActive: null,
			addressPopupShown: false,
			isMobile: window.innerWidth <= 567
		}
	},
	methods: {
		showAddressPopup()
		{
			(window.innerWidth <= 567 && !this.basketType) && (this.addressPopupShown = true);
		},

		closeAddressPopup()
		{
			(window.innerWidth <= 567) && (this.addressPopupShown = false);
		},

		/**
		 * Отрабатывает при выборе адреса
		 * отправляем emit об этом
		 */
		async selectMapAddress()
		{
			this.selectInProgress = true;

			setTimeout(async () =>
			{
				this.$emit('selectAddress', this.deliveryAddress);

				let geo = (await ymaps.geocode(this.deliveryAddress))
					.geoObjects.get(0);

				let precision = geo.properties.get('metaDataProperty.GeocoderMetaData.precision');
				this.$store.dispatch('map/setCurrentAddressPrecision', precision);

				if (precision === 'exact')
					this.$store.commit('map/SET_MAP_UPDATE', true);

				setTimeout(() =>
				{
					this.selectInProgress = false
				}, 1000);
			}, 175)
			// при смене ресторана менять день на сегодня
			await this.$store.dispatch("setDate", 'today');


		},

		closeSuggestItemsPopup()
		{
			this.suggestItems = false;
		}
	},

	computed: {
		...mapGetters({
			deliveryAddress: 'map/getDeliveryAddress'
		}),

		buildingRequired()
		{
			return this.$store.state.map.currentAddressPrecision !== 'exact' && this.$store.state.map.currentAddressPrecision !== '';
		},

		buildingRequiredText()
		{
			if (this.$store.state.map.currentAddressPrecision !== 'exact' && this.$store.state.map.currentAddressPrecision !== '')
			{
				return this.$store.state.map.currentAddressPrecision !== 'street' ? 'Укажите улицу и номер дома' : 'Укажите номер дома';
			}
		}
	},

	/**
	 * При создании попапа смотрим своство, и ставим его в поле ввода
	 */
	mounted()
	{
		this.initialActions();
		this.placeholderShowOnBlurSearchInput();
		this.suggestActive = this.returnCutSuggest.length && (window.innerWidth <= 567);
	},
}
</script>
<style lang="scss">

.search-wr
{
	height: 56px;
	border-radius: 10px;
	display: flex;
	justify-content: center;

	@media (max-width: 567px)
	{
		flex-direction: column;
	}

	&.basket-type
	{
		.search-label
		{
			background: #F8F8F8;
			border: 1px solid #E5E5E7;
			border-radius: 8px;
			box-shadow: none;
		}
	}
}

.search-label
{
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 613px;
	display: flex;
	border-radius: 8px;
	border: 1px solid $greyBackground;
	box-shadow: 0px 10px 16px 0px rgba(34, 60, 80, 0.2);
	background-color: #fff;
	margin-right: 16px;
	align-items: center;

	&_focused
	{
		background: $lightGreenBackground;
		outline: unset;

		& .search__map
		{
			background: $lightGreenBackground;
		}
	}

	&:hover
	{
		background: $lightGreenBackground;

		& .search__map
		{
			background: $lightGreenBackground;
		}
	}
}

.search
{
	height: 100%;
	flex: 1;
	font-family: $mainFontMediumNew;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: $textColor;
	outline: none;
	border: none;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	background: transparent;

	@include ease(.125s);

	&__city-found
	{
		font-size: 16px;

		& span
		{
			color: $green;
			font-size: inherit !important;
		}
	}

	&.error
	{
		color: #fd470e;
	}

	&__error
	{
		color: $red;
	}

	&::placeholder
	{
		color: $textColor;
	}

	&__map
	{
		font-family: $mainFontMediumNew;
		color: $green;
		cursor: pointer;
		font-size: 14px;
		line-height: 14px;
		position: absolute;
		right: 20px;
		display: flex;
		align-items: center;
		height: 100%;
		background: #fff;

		&:before
		{
			content: '';
			display: block;
			position: absolute;
			left: -22px;
			height: 32px;
			width: 1px;
			background: $greyBorder;
		}

		& svg
		{
			margin-right: 6px;
		}

		@media (max-width: 767px)
		{
			font-size: 14px;
			right: 8px;
			padding-left: 8px;

			&:before
			{
				left: 0;
			}

			& svg
			{
				display: none;
			}
		}
	}

	&__subscribe
	{
		padding: 32px 40px;
		width: 738px;
		height: 270px;
	}

	&-label
	{
		padding-right: 120px;

		.search__map_address
		{
			&-input
			{
				font-family: $mainFontMediumNew;
				font-weight: 500;
				font-size: 16px;
				line-height: 14px;
				width: 100%;
				height: 24px;
				border: none;
				background: none;
				position: relative;
				bottom: 9px;
				top: 5px;
				padding: 0;
				margin-right: 0;

				&:focus + .search__map_address-label
				{
					font-family: $mainFontNew;
					font-weight: 400;
					font-size: 11px;
					color: #979797 !important;
					transform: translateY(-13px);
					background: transparent;
				}
			}

			&-label
			{
				font-family: $mainFontMediumNew;
				font-weight: 500;
				font-size: 16px;
				line-height: 14px;
				width: fit-content;
				height: auto;
				display: flex;
				align-items: center;
				position: absolute;
				left: 56px;
				background: transparent;
				color: #3D4248 !important;
				transition: all .3s ease-in-out;
				pointer-events: none;

				&_active
				{
					font-family: $mainFontNew;
					font-weight: 400;
					font-size: 11px;
					color: #979797 !important;
					transform: translateY(-13px);
					background: transparent;
				}
			}
		}
	}
}

.search-icon,
{
	line-height: 0;
	z-index: 1;
	width: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px
}

.search-main-popup-wr
{
	height: 80px;
	box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}

// ANCHOR: Стили поля ввода при ошибке
.search-main-popup_error
{
	color: $red;
	position: absolute;
	font-size: 12px;
	bottom: -20px;
}

.delivery-search.have-error
{
	border: 1px solid $red !important;
}

.search__btn
{
	padding: 18px 97px;
	box-shadow: 0px 10px 16px 0px rgba(34, 60, 80, 0.2);
	background: $red;
	color: #fff;
	font-size: 16px;
	line-height: 20px;
	font-weight: bold;
	border: none;
	outline: none;
	border-radius: 8px;
	font-family: $mainFontBoldNew;
	white-space: nowrap;

	&_no-shadow
	{
		box-shadow: none;
		width: 295px;
		min-width: 295px;
		display: flex;
		padding: 0;
		justify-content: center;
		align-items: center;
	}

	&.loading
	{
		background: #E5E5E7;
	}

	&.basket-type
	{display: none;}

	&:disabled
	{
		background: #E5E5E7;
		color: $greyLightText;
	}
}

.search-main-popup-label
{
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	border: 2px solid #fff;
	background-color: #fff;
	border-radius: 10px;

	&_focused
	{
		border-color: $green;
		box-shadow: 0px 4px 30px rgba(64, 174, 73, 0.44);
		outline: unset;
	}
}

.search-main-popup_city
{
	font-family: $mainFontMediumNew;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	background-color: #fff;
	display: flex;
	align-items: center;
}

.search-main-popup
{
	height: 100%;
	flex: 1;
	font-family: $mainFontMediumNew;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: $textColor;
	padding-right: 88px;
	outline: none;
	border: none;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	@include ease(.125s);

	&.error
	{
		color: #fd470e;
	}

	&::placeholder
	{
		color: $textColor;
	}
}

.search-label.delivery-search
{
	padding-right: 12px;
}

.search-main-popup-icon,
{
	z-index: 1;
	width: 81px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px
}

.search-main-popup__btn
{
	position: absolute;
	z-index: 1;
}

.search-main-popup-icon
{
	left: 30px;
	top: 28px;
}

.search-main-popup__btn
{
	position: absolute;
	z-index: 1;
	right: 10px;
	top: 7px;
	padding: 0;
	width: 60px;
	height: 60px;
}

.search-main-popup__suggestion
{
	padding-bottom: 8px;
	cursor: pointer;
	@include ease(.125s);

	font-family: $mainFontMediumNew;
	color: $textColor;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	border-bottom: 1px solid $greyDelimiter;

	&:last-child
	{
		padding-bottom: 0;
		border-bottom: none;
	}

	&_active
	{
		background-color: rgba(#40AE49, .1);
	}
}

.search-main-popup__suggestions
{
	margin: 0;
	display: flex;
	flex-direction: column;
	border: 1px solid $greyDelimiter;
	gap: 8px;
	padding: 16px;
	z-index: 3;
	background: #FFFFFF;
	border-radius: 8px;
	width: 100%;
	position: absolute;
	top: calc(100% + 2px);
}

.search-main-popup__error
{
	position: absolute;
	color: $red;
	top: calc(100% + 3px);
}

@media (max-width: 1279px)
{
	.search
	{
		font-size: 16px;
	}
}

@media (max-width: 1369px)
{
	.search
	{
		margin-right: 58px;
		width: 100%;
		padding: 0;
		font-size: 14px;
		line-height: 17px;
		text-overflow: ellipsis;
		overflow: hidden;

		&.error
		{
			font-size: 8px;
		}

		&-label
		{
			max-width: 100% !important;
		}

		&__error
		{
			display: none;
		}

		&__subscribe
		{
			width: 680px;
			height: 270px;
		}
	}

	.search-wr
	{
		height: auto;
		margin-bottom: 0;
	}
	.search-label
	{
		height: 56px;
		max-width: 100%;
		margin-right: 0;
		margin-bottom: 16px;
		align-items: center;
	}
	.search_city
	{
		margin-right: 2px;
		font-size: 14px;
		display: none;
	}
	.search__on-map-adaptive
	{
		display: block;
	}
}

@media (max-width: 767px)
{
	.search
	{
		&__subscribe
		{
			width: 100%;
		}

		&-label
		{
			padding-right: 66px;
		}
	}
	.search-main-popup-wr
	{
		height: 50px;
		margin-left: 13px;
	}
	.search-main-popup
	{
		padding-left: 0;
		padding-right: 54px;
		font-size: 14px;
		line-height: 17px;
		width: 49vw;
		text-overflow: ellipsis;
		overflow: hidden;

		&.error
		{
			font-size: 8px;
		}
	}
	.search-main-popup-icon
	{
		left: 15px;
		top: 13px;
		margin-right: 12px;
		width: 23px;
	}
	.search-main-popup__btn
	{
		padding: 8px 11px 8px;
		right: 4px;
		top: 4px;
		width: 36px;
		height: 36px;

		svg
		{
			width: 9px;
		}
	}
	.search-main-popup-label
	{
		padding-left: 13px;
	}
	.search-main-popup_city
	{
		font-size: 14px;
	}
}

@media (max-width: 567px)
{
	.search
	{
		&__btn
		{
			padding: 18px;

			&_no-shadow
			{
				min-width: unset;
			}
		}

	}

	.search-main-popup__suggestions
	{display: none;}
}

@media (max-width: 374px)
{
	.search
	{
		&-label
		{
			height: 48px;

			.search__map_address-label
			{
				left: 46px;
			}
		}

		&-icon
		{
			width: 44px;
		}

		&__btn
		{
			height: 48px;
			padding: 14px;
		}
	}
}
</style>
