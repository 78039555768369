<template>
	<div class="ui-button-preloader">
		<div class="circle"/>
	</div>
</template>

<script>
export default {
	name: 'ButtonPreloader'
}
</script>

<style lang="scss">
.ui-button-preloader
{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 50px;

	.circle
	{
		position: relative;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: rgba(255, 255, 255, 0.4);
		animation: dotFlashing .5s infinite linear alternate;
		animation-delay: .25s;
	}

	.circle::before, .circle::after
	{
		content: '';
		display: inline-block;
		position: absolute;
		top: 0;
	}

	.circle::before
	{
		left: -18px;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: rgba(255, 255, 255, 0.4);
		animation: dotFlashing .5s infinite alternate;
		animation-delay: 0s;
	}

	.circle::after
	{
		left: 18px;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: rgba(255, 255, 255, 0.4);
		animation: dotFlashing .5s infinite alternate;
		animation-delay: .5s;
	}

}

@keyframes dotFlashing
{
	0%
	{
		background-color: #fff;
	}
	50%,
	100%
	{
		background-color: rgba(255, 255, 255, 0.4);
	}
}
</style>