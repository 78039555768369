<template>
	<Ymap
		@mapReady="map=$event"
		:mapid="mapid"
		:isAvailable="isAvailable"
	/>
</template>
<script>
import Ymap from '@/components/block/geo/DeliveryMap.vue';
import {mapState, mapActions, mapGetters} from "vuex";
import search from "@/mixins/search";
import debounce from "lodash/debounce";

export default {
	name: 'Map',
	props: ['mapid', 'isAvailable'],
	components: {Ymap},
	mixins: [search],
	/**
	 * дата
	 */
	data()
	{
		return {
			map: false,
			myPlacemark: false,
			zones: [],
			locAddress: this.localAddress,
			polygons: [],
		}
	},
	computed: {
		...mapState('map', ['fullZones']),
		...mapGetters({
			currentAddress: 'map/getDeliveryAddress',
			cities: 'getCities',
		})
	},
	methods: {
		...mapActions('map', ['getAddress', 'getDisplayAddress', 'getZones', 'drawZones']),
		addDragEventToMap()
		{
			this.$store.dispatch('map/setNewMapAfterChange', this.map);

			if (!this.map) return;

			this.map.events.add('actionbegin', (e) =>
			{
				this.$store.commit('map/setDisableSelectButton', true);
			});

			this.map.events.add('actionend', debounce(async (e) =>
			{
				this.$store.commit('map/setDisableSelectButton', false);
				const coords = e.get('target').getCenter();
				this.$store.dispatch('map/setCurrentAddressPrecision', 'exact');
				await this.getAddress({coords: coords});
				this.detectNotification(coords);
			}, 100));
		},

		detectNotification(coords)
		{
			this.polygons.forEach((zone, index) =>
			{
				if (zone.geometry.contains(coords))
				{
					this.$store.dispatch('mapPopupNotificationTypeAction', false);

					//сохранение города для его смены, если выбран адрес в зоне другого города
					let zoneCity = this.cities.find(city => city.ID === this.zones[index].UF_CITY);
					this.$store.dispatch('map/setCurrentAddressCity', zoneCity);
				}
			});

			const zoneHitMarker = this.polygons.every(zone =>
			{
				return zone.geometry.contains(coords) !== true;
			});

			zoneHitMarker && this.$store.dispatch('mapPopupNotificationTypeAction', 'no-rests') && this.$store.dispatch('map/setCurrentAddressCity', false);
			this.$emit('selectAddress');
			this.$emit('coords', coords);
		},

		updateZones()
		{
			this.polygons = [];
			this.drawZones(this);
			this.addDragEventToMap();

			if (!this.map) return;

			this.detectNotification(this.map.getCenter());
		}

	},

	mounted()
	{
		if (this.fullZones)
		{
			this.zones = this.fullZones;
			// this.updateZones(); -- при вызове из моунтеда self.map не определен
		}
	},

	watch: {
		async map()
		{
			await this.getZones();
			this.zones = this.fullZones;
			this.updateZones();
		}
	}
}
</script>
<style lang="scss">
</style>
